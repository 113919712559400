import React, { useRef, useState } from "react";
import { Map, Marker, Pane, TileLayer } from "react-leaflet";
import { useOnScreen } from "../hooks/useOnScreen";
import { useSelector } from "react-redux";
import { useSplitmap } from "../hooks/useSplitmap";

const { REACT_APP_ZOOM, REACT_APP_START_POINT } = process.env;

const LeafletOnly = (props) => {
  const mapRef = useRef();
  const sliderRef = useRef();
  const rightPaneRef = useRef();
  const leftPaneRef = useRef();

  const defaultCenter = props.startOnMobile
    ? JSON.parse(REACT_APP_START_POINT).mobile
    : JSON.parse(REACT_APP_START_POINT).desktop;

  const zoom = props.startOnMobile ? JSON.parse(REACT_APP_ZOOM).mobile : JSON.parse(REACT_APP_ZOOM).desktop;
  const minZoom = +JSON.parse(REACT_APP_ZOOM)["min"];
  const maxZoom = +JSON.parse(REACT_APP_ZOOM)["max"];
  const [center, setCenter] = useState(defaultCenter);
  const [rootRef, onScreen] = useOnScreen();
  const splitmapObjects = useSelector((state) => state.map.splitmapObjects);

  useSplitmap(sliderRef, leftPaneRef, rightPaneRef, mapRef);

  // Map attribution
  const attribution =
    '&copy; <a href="https://latitude-cartagene.com" target="_blank" rel="noopener">Latitude-Cartagène</a> | &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a>';

  return (
    <div className="lc-map" ref={rootRef}>
      <Map
        ref={mapRef}
        center={center}
        className={"lc-mapContainer" + (!props.showBoard ? " lc-no-board" : "")}
        zoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        zoomControl={false}
      >
        <Pane name="rightPane" ref={rightPaneRef}>
          <TileLayer
            className="lc-tilelayer-right-pane"
            url={"https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"}
            attribution={attribution}
          />
          {splitmapObjects.right.length > 0 && splitmapObjects.right}
        </Pane>
        <Pane name="leftPane" ref={leftPaneRef}>
          <TileLayer
            className="lc-tilelayer-left-pane"
            url={"https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"}
            attribution={attribution}
          />
          <Marker position={{ lat: 1, lon: 2 }}></Marker>
          {splitmapObjects.left.length > 0 && splitmapObjects.left}
        </Pane>
      </Map>
      <div className="lc-slider" ref={sliderRef}>
        <div className="lc-before">
          <span>2020</span>
        </div>
        <div className="lc-between" />
        <div className="lc-after">
          <span>2023</span>
        </div>
      </div>
    </div>
  );
};

export default LeafletOnly;
