import { createaroundPin, debug, isInFrame } from "./tools";
import { appStore } from "../store";
import { buildCustomMarkers } from "./map";
import { actionSetCustomMarkers, actionSetCenter, actionSetZoom, actionSetAroundPin } from "../actions/map";
import { actionSetSliderUrlLeft, actionSetSliderUrlRight } from "../actions/network";
import { actionBuildCustomLines, actionBuildTransportPlaces } from "../actions/withRedux";
import history from "../history";
import { actionSetConfig } from "../actions/app";
import ThematicInterface from "../interfaces/ThematicInterface";
import { actionSetThematicLibDisplay, actionSetThematicLibPredict, actionSetThematicPlaces } from "../actions/board";
import { fitBounds } from "../utils/leaflet/map";
import { isCoords } from "../utils/leaflet/tools";

/**
 * Use postMessage API to pass data through the iframe / library direclty to the main website
 * @see https://developer.mozilla.org/fr/docs/Web/API/Window/postMessage
 * @param {Object} data All data we wanna pass through the main website for this message
 */
export const message = (data) => {
  // Retreive the correct DOM Element to post message
  const element = isInFrame() ? window.parent : window;
  const documentReferer = appStore.getState().app.documentReferer;

  // Retrieve the correct target origin for our message
  const targetOrigin =
    process.env.REACT_APP_ENV === "local"
      ? "*"
      : process.env.REACT_APP_LIBRARY_URL
      ? window.document.location.origin
      : isInFrame()
      ? documentReferer // document.referrer // document.location.ancestorOrigins[0]
      : process.env.REACT_APP_API_PROXY_URL;

  // Post message, or warn in console if something went wrong
  try {
    element.postMessage({ source: "lcmap", ...data }, targetOrigin);
    // Debug for dev & local
    debug({ message: "message sent with", data }, "info", "post message");
  } catch (e) {
    debug({ message: "Error while sending ", data }, "error", "error post message");
    throw new Error("Oops, une erreur est survenue sur l'envoi de données postMessage !");
  }
};

export const receivedMessage = () => {
  window.addEventListener("message", (event) => {
    const geolocOptions = process.env.REACT_APP_PIN_GEOLOC_OPTIONS;

    if (event.data.source === "lcmap-in") {
      if (event.data.route) {
        history.push((!event.data.route.startsWith("/") ? "/" : "") + event.data.route);
      }

      if (event.data.lines !== undefined) {
        appStore.dispatch(actionBuildCustomLines(event.data.lines));
      }

      if (event.data.markers !== undefined) {
        appStore.dispatch(actionSetCustomMarkers(buildCustomMarkers(event.data.markers)));
      }

      if (event.data.zoom) {
        appStore.dispatch(actionSetZoom(event.data.zoom));
      }

      if (event.data.center) {
        appStore.dispatch(actionSetCenter(event.data.center));
      }

      if (event.data.sliderUrls) {
        if (event.data.sliderUrls.right) {
          appStore.dispatch(actionSetSliderUrlRight(event.data.sliderUrls.right));
        }

        if (event.data.sliderUrls.left) {
          appStore.dispatch(actionSetSliderUrlLeft(event.data.sliderUrls.left));
        }
      }

      if (event.data.config) {
        appStore.dispatch(actionSetConfig(event.data.config));
      }

      if (event.data.geolocation !== undefined && geolocOptions) {
        const geolocation = event.data.geolocation;
        const { lat, lng } = geolocation;

        if (geolocation && (!lat || !lng)) {
          message({
            warning: "geolocation need a lat and a lng",
          });
        } else {
          if (!geolocation) {
            appStore.dispatch(actionSetAroundPin({}));
          } else if (geolocation) {
            if (isCoords(`${lng};${lat}`)) {
              if (geolocation) {
                // TODO test if coords are in bounds
                const geoloc = createaroundPin([lat, lng], JSON.parse(geolocOptions));

                appStore.dispatch(actionSetAroundPin(geoloc));
                const map = appStore.getState().app?.component?.props?.map;
                const { aroundCircles, aroundPin } = map?.props;

                if (aroundCircles.length) {
                  fitBounds(map, null, -1, geoloc.refCircle[0].leafletElement.getBounds());
                } else if (aroundPin) {
                  fitBounds(map, [aroundPin]);
                }
              } else {
                message({
                  warning: "geolocation coords out of bounds",
                });
              }
            } else {
              message({
                warning: "invalid geolocation coords",
              });
            }
          }
        }
      }

      if (event.data.thematics) {
        const thematic = appStore.getState().app?.component?.props?.moduleData?.data || null;

        if (thematic) {
          const obj = {};
          const data = event.data.thematics.find((t) => t.name === thematic);
          const interfacedDatas = [];

          const display =
            data.display ||
            appStore.getState().app?.component?.props?.libraryThematicDisplay ||
            appStore.getState().app?.component?.props?.moduleData?.defaultDisplay ||
            null;

          const predict =
            data.predict !== undefined
              ? data.predict
              : appStore.getState().app?.component?.props?.libraryThematicPredict || false;

          if (data.markers) {
            for (const marker of data.markers) {
              if (predict) {
                marker.predict = predict.find(
                  (p) => p[appStore.getState().app?.component?.props?.moduleData?.predictID || "id"] === marker.id
                );
              }

              interfacedDatas.push(
                new ThematicInterface({ ...marker, display }, process.env.REACT_APP_PROJECT.toLowerCase(), thematic)
              );
            }
          } else {
            const markers = appStore.getState().app?.component?.props?.libraryThematicMarkers || null;

            if (markers) {
              for (const marker of markers) {
                if (predict) {
                  marker.predict = predict.find(
                    (p) => p[appStore.getState().app?.component?.props?.moduleData?.predictID || "id"] === marker.id
                  );
                } else {
                  delete marker.predict;
                }

                interfacedDatas.push(
                  new ThematicInterface({ ...marker, display }, process.env.REACT_APP_PROJECT.toLowerCase(), thematic)
                );
              }
            } else {
              message({
                warning: "no markers detected",
              });
            }
          }

          appStore.dispatch(actionSetThematicLibPredict(predict));
          appStore.dispatch(actionSetThematicLibDisplay(display));
          appStore.dispatch(actionBuildTransportPlaces({ thematic: interfacedDatas }, true));
          obj[thematic] = interfacedDatas;
          appStore.dispatch(actionSetThematicPlaces(obj));
        }
      }

      if (event.data.itineraries) {
        const component = appStore.getState().app?.component;
        const { map } = component.props;
        const itineraries = event?.data?.itineraries;
        const journeys = itineraries?.results?.journeys || component.state.journeys;
        let selected = itineraries?.selected || 0;

        if (journeys.length) {
          if (!Number.isInteger(selected) || !journeys[selected]) {
            selected = 0;
            message({
              warning: "selected has been force to 0",
            });
          }

          if (component) {
            if (Object.keys(journeys).length) {
              component.setState({ journeys: journeys, journey: journeys[selected] }, () => {
                component.displayJourneys(journeys, map);
              });
            } else {
              component.setState({ journeys: undefined, journey: null }, () => {
                component.displayJourneys(undefined, map);
              });
            }
          } else {
            message({
              error: "itineraries doesn't exist",
            });
          }
        }
      }
    }
  });
};
