import React, { Component } from "react";
import { Map, TileLayer, Pane, ZoomControl, ScaleControl, GeoJSON, LayerGroup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { detect } from "detect-browser";
import { appStore } from "../store";
import { actionSetMap } from "../actions/app";
import { actionSetCluster, actionHandleLegendState, actionSetDisplayMarkerMode } from "../actions/map";
import {
  envVarToBool,
  getURLSearchParams,
  isNotPlacesTabAround,
  isThematics,
  assetsPath,
  debug,
  handleKeyPress,
} from "../services/tools";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import { renderMapPlaces } from "../services/map";
import history from "../history";
import L from "leaflet";
import Control from "react-leaflet-control";
import Tippy from "@tippy.js/react";

const {
  REACT_APP_TERRITORY_OUTLINE,
  REACT_APP_HEADER,
  REACT_APP_BASE_MAX_BOUNDS,
  REACT_APP_DEMO,
  REACT_APP_BASE_TILES_URL,
  REACT_APP_ZOOM,
  REACT_APP_LEGEND,
  REACT_APP_MACARON,
  REACT_APP_ZOOM_MARKER_MODE_CHANGED,
} = process.env;

const browser = detect();

class Leaflet extends Component {
  mapReference = React.createRef();

  state = {
    bounds: null,
    boundsOptions: null,
    circle: null,
    clusters: null,
    events: {},
    infoboxs: [],
    infoboxsTerminus: [],
    markers: [],
    markersMap: [],
    markersPlaces: [],
    markersRER: [],
    pin: null,
    polygons: [],
    polylines: [],
    polylineDecorators: [],
    terminus: false,
    random: null, // TODO, use to redraw the map for onMoveEnd event...
  };

  componentDidMount() {
    appStore.dispatch(actionSetMap(this));

    // Custom the default attribution to add target blank
    this.mapReference.current.leafletElement.attributionControl.setPrefix(
      '<a href="https://leafletjs.com" target="_blank" rel="noopener">Leaflet</a>'
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.onScreen !== prevProps.onScreen) {
      debug({ message: `Is map on screen : ${this.props.onScreen}` }, "info", "Map on screen");
    }
  }

  render() {
    const {
      events,
      bounds,
      boundsOptions,
      polygons,
      polylines,
      polylineDecorators,
      circle,
      pin,
      markers,
      markersMap,
      markersPlaces,
      markersRER,
    } = this.state;

    const {
      territoryOutline,
      entranceMapMarkers,
      heavyLines,
      customLines,
      customMarkers,
      reduxMarkers,
      aroundCircles,
      aroundPin,
      selectedLine,
      transportPlaces,
      mapPlaces,
      places,
      isMobile,
      isLegendOpen,
      bikePaths,
      bikes,
      reactTourismPartnersStops,
      reactLines,
      showBoard,
      zoom,
      center,
      config,
      markerMode,
    } = this.props;

    const pathname = history.location.pathname;
    const search = history.location.search;
    const params = getURLSearchParams(history.location);
    const map = this.mapReference?.current?.leafletElement;
    // TODO zoom duplicate but in state it's not updated
    let zoomTmp = null;

    if (map) {
      zoomTmp = map.getZoom();
    }

    let entranceItems = [];

    if (entranceMapMarkers) {
      entranceItems = entranceMapMarkers.filter((marker) => {
        if (
          !marker.props.zoom ||
          (marker.props.zoom.min && !marker.props.zoom.max && marker.props.zoom.min <= zoomTmp) ||
          (!marker.props.zoom.min && marker.props.zoom.max && marker.props.zoom.max >= zoomTmp) ||
          (marker.props.zoom.min <= zoomTmp && marker.props.zoom.max >= zoomTmp)
        ) {
          return marker;
        }

        return false;
      });
    }

    // Zoom handling
    if (REACT_APP_ZOOM_MARKER_MODE_CHANGED) {
      if (zoomTmp < REACT_APP_ZOOM_MARKER_MODE_CHANGED && markerMode === "default") {
        appStore.dispatch(actionSetDisplayMarkerMode("small"));
      } else if (zoomTmp >= REACT_APP_ZOOM_MARKER_MODE_CHANGED && markerMode !== "default") {
        appStore.dispatch(actionSetDisplayMarkerMode("default"));
      }
    }

    // Detect Safari or Edge 17 browsers to fallback tiles on PNG
    const ext =
      browser.name === "safari" ||
      browser.os === "iOS" ||
      (browser.name === "edge" && browser.version.startsWith("17."))
        ? "png"
        : "webp";

    return (
      <Map
        ref={this.mapReference}
        className={
          "lc-mapContainer" +
          (envVarToBool(REACT_APP_HEADER) ? " lc-with-header" : "") +
          (!showBoard ? " lc-no-board" : "")
        }
        bounds={bounds}
        maxBounds={JSON.parse(REACT_APP_BASE_MAX_BOUNDS)[isMobile ? "mobile" : "desktop"]}
        boundsOptions={boundsOptions}
        zoomControl={false}
        center={center}
        minZoom={+JSON.parse(REACT_APP_ZOOM)["min"]}
        maxZoom={+JSON.parse(REACT_APP_ZOOM)["max"]}
        zoom={zoom}
        scrollWheelZoom={config.scrollWheelZoom}
        dragging={config.dragging}
        {...events}
      >
        {REACT_APP_DEMO && (
          <TileLayer
            attribution='&amp;copy <a href="https://latitude-cartagene.com" target="_blank" rel="noopener">Latitude-Cartagène</a> | &amp;copy <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a>'
            errorTileUrl={assetsPath("/assets/images/blank.png")} // Grey tile if 404
            url={`http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png`}
          />
        )}
        {REACT_APP_BASE_TILES_URL && (
          <TileLayer
            attribution='&amp;copy <a href="https://latitude-cartagene.com" target="_blank" rel="noopener">Latitude-Cartagène</a> | &amp;copy <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a>'
            errorTileUrl={assetsPath("/assets/images/blank.png")} // Grey tile if 404
            url={`${REACT_APP_BASE_TILES_URL}/${ext}/{z}/{x}/{y}.${ext}`}
          />
        )}
        {(!isMobile || config.scaleControl) && <ScaleControl position={"bottomright"} imperial={false} />}
        {(!isMobile || config.zoomControl) && <ZoomControl position={"bottomright"} />}
        {envVarToBool(REACT_APP_MACARON) && !isMobile && (
          <img className={"lc-leaflet-map-macaron"} src={assetsPath("/assets/images/macaron.svg")} alt="macaron" />
        )}
        {REACT_APP_LEGEND && (
          <Control
            className={"lc-leaflet-control-legend"}
            position={isMobile ? "topright" : "bottomright"}
            updateWhenIdle={true}
          >
            <Tippy theme={"latitude"} touch={["hold", 500]} placement={"left"} boundary="window" content={"Légende"}>
              <div
                onClick={() => appStore.dispatch(actionHandleLegendState(isLegendOpen))}
                onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionHandleLegendState(isLegendOpen)))}
                role="button"
                tabIndex="0"
              >
                <img src={assetsPath("/assets/images/legend-item.svg")} height={25} width={25} alt="legend" />
              </div>
            </Tippy>
          </Control>
        )}
        {/* // TODO TOOLS */}
        {territoryOutline && (
          <GeoJSON
            data={territoryOutline}
            style={config.territory ? config.territory : JSON.parse(REACT_APP_TERRITORY_OUTLINE)}
          />
        )}

        {customLines.length > 0 ? (
          <LayerGroup>
            {customLines} {customMarkers}
          </LayerGroup>
        ) : (
          <LayerGroup>
            {!pathname.includes("route-calculation") && !search.includes("current") && heavyLines}
            {!pathname.includes("route-calculation") && !search.includes("current") && entranceItems}
            {customMarkers}
            {bikePaths} {bikes}
            {polygons} {polylines} {polylineDecorators}
            {reactLines}
            {selectedLine}
            {aroundCircles} {aroundPin}
            {circle} {pin}
            {markersMap}
            {markers} {markersPlaces} {markersRER}
            {reactTourismPartnersStops}
            {transportPlaces && (
              <Pane name="cluster-pane" style={isThematics() ? { zIndex: 600 } : { zIndex: 400 }}>
                <MarkerClusterGroup
                  key="transport-places"
                  ref={(ref) => ref && appStore.dispatch(actionSetCluster(ref.leafletElement))}
                  removeOutsideVisibleBounds
                  showCoverageOnHover={false}
                  clusterPane="cluster-pane"
                  iconCreateFunction={(cluster) => {
                    return L.divIcon({
                      html: cluster.getChildCount(),
                      className: "lc-cluster",
                    });
                  }}
                >
                  {transportPlaces.filter((place) => place.props.place.clusterized)}
                </MarkerClusterGroup>
              </Pane>
            )}
            {transportPlaces && transportPlaces.filter((place) => !place.props.place.clusterized)}
            {places}
            {(!transportPlaces || transportPlaces.length === 0) &&
              !search.includes("current=") &&
              !isNotPlacesTabAround(places) &&
              renderMapPlaces(this.mapReference, mapPlaces)}
            {params.current || params.line ? reduxMarkers : (!zoomTmp || zoomTmp > 14) && reduxMarkers}
          </LayerGroup>
        )}
      </Map>
    );
  }
}

export default Leaflet;
