import React, { useEffect, useRef, useState } from "react";
import { Map, TileLayer, ScaleControl, ZoomControl, Polyline } from "react-leaflet";
import { useSelector } from "react-redux";
import { envVarToBool, debug, translate, assetsPath } from "../services/tools";
import { useRouter } from "../hooks/useRouter";
import { useMedia } from "../hooks/useMedia";
import { getCoordinates } from "../utils/leaflet/map";
import history from "../history";
import L from "leaflet";
import { Legend } from "./map/Legend";
import { Information } from "./map/Information";

const { REACT_APP_HEADER, REACT_APP_ZOOM } = process.env;

const LeafletProjects = (props) => {
  const mapRef = useRef();
  const { menuID = 1, modules, linesProjects, linesProjectsModes } = useSelector((state) => state.app);
  const { zoom, center } = useSelector((state) => state.map);
  const { openedCollapse } = useSelector((state) => state.board);
  const minZoom = +JSON.parse(REACT_APP_ZOOM)["min"];
  const maxZoom = +JSON.parse(REACT_APP_ZOOM)["max"];
  const router = useRouter();
  const [lines, setLines] = useState([]);
  const [tiles, setTiles] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [defaultBounds, setDefaultBounds] = useState(null);
  const [projects, setProjects] = useState([]);
  const [previous, setPrevious] = useState(null);
  const [legend, setLegend] = useState(menuID);
  const isDesktop = useMedia();
  const map = mapRef?.current?.leafletElement;

  useEffect(() => {
    const fetchLines = async () => {
      const response = await fetch("assets/lines.geojson");
      const data = await response.json();

      setLines(data);
    };

    fetchLines();
  }, []);

  useEffect(() => {
    map && setDefaultBounds(map.getBounds());
  }, [map]);

  useEffect(() => {
    setLegend(menuID || 1);
  }, [menuID]);

  useEffect(() => {
    debug({ message: `Here is router query :`, data: router.query }, "info", "Routing from projects");

    setTiles(router.query.tiles);

    if (router.pathname === "/") {
      setProjects([]);
      //dispatch(actionSetProjectsMenuID(null));
    }

    if (linesProjects) {
      const features = lines?.features?.map((line) => {
        const project = linesProjects.find((l) => l.tiles === line.properties.id);

        line.properties.menu = project?.menu || null;
        return line;
      });

      lines.features = features;

      setLines(lines);
    }

    if (router.query.line) {
      if (lines.features && linesProjects) {
        const project = linesProjects.find((l) => String(l.id) === router.query.line);
        const line = lines.features.find((feature) => feature.properties.id === project.tiles);

        // Avoid infinite loop
        if (previous !== router.query.line) {
          handleLineClick(line);
          setPrevious(router.query.line);
        }
      }
    } else {
      setBounds(defaultBounds);
      setPrevious(null);
    }

    // eslint-disable-next-line
  }, [router, lines, linesProjects]);

  /**
   * Load each projects tiles of the current mode.
   * If no mode open, no projects tiles are rendered.
   * If one project is selected, only this one should be rendered
   */
  useEffect(() => {
    if (openedCollapse) {
      if (linesProjects) {
        if (router.query.line) {
          const project = linesProjects.filter((l) => String(l.id) === router.query.line);

          setProjects(project);
        } else {
          const mode = linesProjectsModes.find((mode) => mode.name === openedCollapse);

          if (mode) {
            const allProjects = linesProjects
              .filter((project) => String(project.menu).includes(menuID))
              .filter((project) => project.mode === mode.modes);

            setProjects(allProjects);
          }
        }
      }
    } else if (!router.query.line) {
      setProjects(null);
    }

    // eslint-disable-next-line
  }, [openedCollapse, router]);

  const handleLineClick = (line) => {
    if (linesProjects && router.query.line !== line.properties.id) {
      let tiles = router.query.tiles;
      let pathname = history.location.pathname;

      // Handle a way to enter default module through the map
      if (!router.query.tiles) {
        const module = modules.find((module) => module.position === menuID);

        tiles = module.tiles;
        pathname = module.data;
      }

      const project = linesProjects.find((l) => l.tiles === line.properties.id);
      const bounds = L.latLngBounds(line.geometry.coordinates.map((c) => [c[1], c[0]]));

      setBounds(bounds);

      history.push({
        pathname,
        search: `?tiles=${tiles}&line=${project.id}`,
      });
    }
  };

  // Map attribution
  const attribution =
    '&copy; <a href="https://latitude-cartagene.com" target="_blank" rel="noopener">Latitude-Cartagène</a> | &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a>';

  const module = modules.find((module) => module.position === menuID);
  const information = module?.information;
  const interactive = module?.interactive;

  return (
    <div className="lc-map">
      <Map
        ref={mapRef}
        center={center}
        className={
          "lc-mapContainer" +
          (envVarToBool(REACT_APP_HEADER) ? " lc-with-header" : "") +
          (!props.showBoard ? " lc-no-board" : "")
        }
        zoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        bounds={bounds || defaultBounds || null}
        zoomControl={false}
      >
        {isDesktop && (
          <>
            <ScaleControl position={"bottomright"} imperial={false} />
            <ZoomControl position={"bottomright"} />
          </>
        )}

        {interactive && isDesktop && (
          <img
            className={"lc-leaflet-map-macaron"}
            src={assetsPath("/assets/images/interactive.svg")}
            alt="interactive"
          />
        )}

        {information && isDesktop && <Information message={translate(information)} />}
        <Legend
          image={`assets/images/legend/${legend}.svg`}
          legendIcon={"assets/images/legend/icon.svg"}
          defaultOpen={false}
        />

        <TileLayer url={`assets/tiles/FONDS/FOND/{z}/{x}/{y}.png`} attribution={attribution} />

        {menuID === 1 && (
          <TileLayer
            url={`assets/tiles/CARTES_NON_INTERACTIVES/CARTE_ENTREE/{z}/{x}/{y}.png`}
            attribution={attribution}
          />
        )}

        {tiles && <TileLayer url={`assets/tiles/${tiles}/{z}/{x}/{y}.png`} />}
        {projects?.map((project) => (
          <TileLayer key={project.id} url={`assets/tiles/LIGNES/${project.tiles}/{z}/{x}/{y}.png`} />
        ))}
        {lines?.features
          ?.filter((line) => (menuID ? String(line.properties.menu).includes(menuID) : null))
          .map((line, index) => (
            <Polyline
              key={line.properties.id + "_" + index}
              positions={getCoordinates(line)}
              onClick={() => handleLineClick(line)}
              {...{
                opacity: 0,
                weight: 10,
              }}
            />
          ))}
      </Map>
    </div>
  );
};

export default LeafletProjects;
