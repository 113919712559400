export default class ThematicInterface {
  constructor(thematic, project, type) {
    switch (project) {
      case "bordeaux-tbm":
        const cat = `${type}`;
        let totalBikes = thematic.nbBikeAvailable + thematic.nbElectricBikeAvailable;
        let totalPlaces = thematic.nbPlaceAvailable;
        const display = thematic.display;
        const predict = thematic.predict;

        const color = ["vcub"].includes(type)
          ? parseInt(display === "bikes" ? totalBikes : totalPlaces) >= 3
            ? predict
              ? "blue"
              : "green"
            : parseInt(display === "bikes" ? totalBikes : totalPlaces) > 0
            ? "orange"
            : "red"
          : null;

        const vcubPlus =
          ["vcub"].includes(type) && thematic.typeVlsPlus && (display === "bikes") === "VLS_PLUS" ? "+" : "";

        this.cat = cat;
        this.cat_id = `poi_type:${cat}`;
        this.subcat = "empty-subcat";
        this.code = ["vcub"].includes(type) ? `${cat}${vcubPlus}_${display !== "bikes" ? "slots_" : ""}${color}` : cat;
        this.name = thematic.name;
        this.label = thematic.name;
        this.id = thematic.id;
        this.coord = {
          lat: thematic.latitude,
          lon: thematic.longitude,
        };
        this.stand = {
          available_bikes: thematic.nbBikeAvailable,
          available_electric_bikes: thematic.nbElectricBikeAvailable,
          available_total_bikes: totalBikes,
          available_places: totalPlaces,
        };

        if (predict) {
          this.stand["time"] = predict.tau;
          this.stand["available_bikes"] = predict.bikes;
          this.stand["available_total_bikes"] = predict.bikes;
          this.stand["available_places"] = predict.free_slots;
          totalBikes = predict.bikes;
          totalPlaces = predict.free_slots;
        }

        this.divIcon = ["vcub"].includes(type);
        this.color = color;
        this.value = display === "bikes" ? totalBikes : totalPlaces;
        break;
      default:
        break;
    }
  }
}
